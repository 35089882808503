const firebaseConfig = {
    apiKey: "AIzaSyA-Mipgt15bA0HutX1HHraDMaApwYTyj0I",
    authDomain: "startuphive-portal.firebaseapp.com",
    databaseURL: "https://startuphive-portal.firebaseio.com",
    projectId: "startuphive-portal",
    storageBucket: "startuphive-portal.appspot.com",
    messagingSenderId: "709228578661",
    appId: "1:709228578661:web:34305361a4e8e3b314c38c",
    measurementId: "G-KT9408LF7W",
}

export default firebaseConfig