const FETCH_MAIL_REQUEST = 'FETCH_MAIL_REQUEST'
const FETCH_MAIL_SUCCESS = 'FETCH_MAIL_SUCCESS'
const FETCH_MAIL_ERROR = 'FETCH_MAIL_ERROR'
const FILTER_MAIL = 'FILTER_MAIL'
const TRASH_MAIL_REQUEST = 'TRASH_MAIL_REQUEST'
const TRASH_MAIL_SUCCESS = 'TRASH_MAIL_SUCCESS'
const TRASH_MAIL_ERROR = 'TRASH_MAIL_ERROR'
const GET_SUITES_REQUEST = 'GET_SUITES_REQUEST'
const GET_SUITES_SUCCESS = 'GET_SUITES_SUCCESS'
const GET_SUITES_ERROR = 'GET_SUITES_ERROR'
const UPDATE_SUITE_REQUEST = 'UPDATE_SUITE_REQUEST'
const SELECT_ACCOUNT = 'SELECT_ACCOUNT'
const SELECT_SUITE = 'SELECT_SUITE'
const SELECT_LANGUAGE = 'SELECT_LANGUAGE'
const CLEAR_MAILBOX_PAGE = 'CLEAR_MAILBOX_PAGE'

export default {
    FETCH_MAIL_REQUEST,
    FETCH_MAIL_SUCCESS,
    FETCH_MAIL_ERROR,
    FILTER_MAIL,
    TRASH_MAIL_REQUEST,
    TRASH_MAIL_SUCCESS,
    TRASH_MAIL_ERROR,
    GET_SUITES_REQUEST,
    GET_SUITES_SUCCESS,
    GET_SUITES_ERROR,
    UPDATE_SUITE_REQUEST,
    SELECT_ACCOUNT,
    SELECT_SUITE,
    SELECT_LANGUAGE,
    CLEAR_MAILBOX_PAGE,
}