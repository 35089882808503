const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST'
const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR'
const SELECT_ACCOUNT = 'SELECT_ACCOUNT'
const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS'

export default {
    GET_ACCOUNTS_REQUEST,
    GET_ACCOUNTS_SUCCESS,
    GET_ACCOUNTS_ERROR,
    SELECT_ACCOUNT,
    CLEAR_ACCOUNTS,
}