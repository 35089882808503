const LOG_IN = 'LOG_IN';
const LOG_OUT = 'LOG_OUT';
const SET_ACCOUNT = 'SET_ACCOUNT';
const ACCOUNT_CHECK = 'ACCOUNT_CHECK';

export default {
    LOG_IN,
    LOG_OUT,
    SET_ACCOUNT,
    ACCOUNT_CHECK
}