import types from './types'

const INITIAL_STATE = {
    accounts: [],
    accounts_loading: false,
    accounts_error: null
}

const adminReducers = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.GET_ALL_ACCOUNTS_REQ:
            return {
                ...state,
                accounts_loading: true,
                accounts: [],
            }
        
        case types.GET_ALL_ACCOUNTS_SUCCESS:
            return {
                ...state,
                accounts_loading: false,
                accounts: action.payload.accounts,
                accounts_error: null,
            }

        case types.GET_ALL_ACCOUNTS_ERROR:
            return {
                ...state,
                accounts_loading: false,
                accounts: [],
                accounts_error: null
            }
        
        case types.CLEAR_ACCOUNTS:
            return {
                ...state,
                accounts: []
            }

        default:
            return state
    }
}

export default adminReducers