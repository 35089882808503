import types from './types'

const INITIAL_STATE = {
    mail_data: [],
    mail_data_loading: false,
    mail_data_loaded: false,
    mail_data_error: null,
    trash_mail_error: null,
    trash_mail_processing: false,
    trash_mail_success: false,
    suites_loading: false,
    suites_loaded: false,
    suites_error: null,
    suites: '',
    selected_account: '',
    selected_suite: '',
    language: 'en',
    filter: '',
}

const mailboxReducers = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case types.FETCH_MAIL_REQUEST:
            return {
                ...state,
                mail_data_loading: true
            }
        case types.FETCH_MAIL_SUCCESS:
            return {
                ...state,
                mail_data_loading: false,
                mail_data_error: null,
                mail_data: action.payload.data
            }
        case types.FETCH_MAIL_ERROR:
            return {
                ...state,
                mail_data_loading: false,
                mail_data_error: action.payload.err
            }
        case types.FILTER_MAIL:
            return {
                ...state,
                filter: action.payload.filter
            }
        case types.TRASH_MAIL_REQUEST:
            return {
                ...state,
                trash_mail_processing: true,
            }
        
        case types.TRASH_MAIL_SUCCESS:
            return {
                ...state,
                trash_mail_processing: false,
                trash_mail_success: true,
                trash_mail_error: null,
                mail_data: state.mail_data.filter(mail => { return mail.id !== action.payload.id }),
            }

        case types.TRASH_MAIL_ERROR:
            return {
                ...state,
                trash_mail_error: action.payload.err
            }

        case types.GET_SUITES_REQUEST:
            return {
                ...state,
                suites_loading: true,
                suites_loaded: false,
            }
        
        case types.GET_SUITES_SUCCESS:
            return {
                ...state,
                suites_loading: false,
                suites_loaded: true,
                suites_error: null,
                suites: action.payload.suites,
            }

        case types.GET_SUITES_ERROR:
            return {
                ...state,
                suites_loading: false,
                suites_loaded: false,
                suites_error: action.payload.err,
            }

        case types.SELECT_ACCOUNT: 
            return {
                ...state,
                selected_account: action.payload.uid,
            }
        
        case types.SELECT_SUITE:
            return {
                ...state,
                selected_suite: action.payload.uid,
            }
        
        case types.SELECT_LANGUAGE:
            return {
                ...state,
                language: action.payload.lang
            }

        case types.CLEAR_MAILBOX_PAGE:
            return INITIAL_STATE
        default: return state;
    }
}

export default mailboxReducers