import React from 'react'
import loadingImg from '../../assets/loader.gif'

const LoadingScreen = () => {
    return (
        <div style={{height: "100vh",width: "100%", backgroundColor:"#fff0f0"}}>
            <img style={{position: 'absolute', left: '50%', top: '50%',height: '40px', width: '40px', opacity: '0.8',
                transform: 'translate(-50%, -50%)'}} src={loadingImg} />
        </div>
    )
}

export default LoadingScreen