import types from './types.js';

const INITIAL_STATE = {
    page_state: 'login',
    reset_pass_cooldown: 0
}

const loginReducers = (state = INITIAL_STATE, action) => {
    switch(action.type){
        default: return state


        //page_state
        case types.SWITCH_TO_FORGOT: 
            return {
                ...state, 
                page_state: 'forgot'
            }
        case types.SWITCH_TO_LOGIN: 
            return {
                ...state,
                page_state: 'login'
            }


        //reset_pass_cooldown
        case types.DECREMENT_COOLDOWN_RESET: 
            if (state === 0) return state
            else 
            return {
                ...state,
                reset_pass_cooldown: state.reset_pass_cooldown - 1
            }
        case types.RESET_COOLDOWN_RESET: 
            return {
                ...state,
                reset_pass_cooldown: action.payload.cooldownTime
            }

    }
}

export default loginReducers
