const SWITCH_TO_FORGOT = 'SWITCH_TO_FORGOT';
const SWITCH_TO_LOGIN = 'SWITCH_TO_LOGIN';
const DECREMENT_COOLDOWN_RESET = 'DECREMENT_COOLDOWN_RESET'
const RESET_COOLDOWN_RESET = 'RESET_COOLDOWN_RESET'

export default {
    SWITCH_TO_FORGOT,
    SWITCH_TO_LOGIN,
    DECREMENT_COOLDOWN_RESET,
    RESET_COOLDOWN_RESET,
}