import types from './types.js';

const INITIAL_STATE = {
    loggedIn: false,
    currentAccount: '',
    accountRole: '',
    user: {},
    accountCheck: true
}

const authReducers = (state = INITIAL_STATE, action) => {
    switch(action.type){
        default: return state

        case types.LOG_IN: 
            return {
                ...state, 
                loggedIn: true,
                user: action.payload.user
            }
        case types.LOG_OUT: 
            return INITIAL_STATE;

        case types.SET_ACCOUNT: 
            return {
                ...state,
                currentAccount: action.payload.account,
                accountRole: action.payload.role
            }

        case types.ACCOUNT_CHECK: 
            return {
                ...state,
                accountCheck: action.payload.status
            }
    }
}

export default authReducers
