import React, { Suspense, lazy } from 'react';

import rootReducerGenerator from './reducers';
import fbConfig from './firebase/firebase.config'
import rrfConfig from './firebase/reactReduxFirebase.config'
import LoadingScreen from './app/common/LoadingScreen'

const App = lazy(() => import('./app/App'));

import('firebase/app').then(firebase => {
  import('firebase/auth').then(() => {
    import('firebase/firestore').then(() => {
      import('firebase/functions').then(() => {
        import('react-dom').then(ReactDOM => {
          import('redux').then(Redux => {
            import('react-redux').then(ReactRedux => {
              import('react-redux-firebase').then(RRF => {
                import('redux-firestore').then(ReduxFirestore => {
                  import('redux-thunk').then(Thunk => {
                    import('redux-persist').then(Persist => {
                      import('redux-persist/integration/react').then(RPReactIntegration => {

                        rootReducerGenerator().then(rootReducer => {
                          firebase.initializeApp(fbConfig)
                          firebase.firestore()
                          
                          const asyncLocalStorage = {
                              setItem: function (key, value) {
                                return Promise.resolve().then(function () {
                                  localStorage.setItem(key, value)
                                })
                              },
                              getItem: function (key) {
                                return Promise.resolve().then(function () {
                                  return localStorage.getItem(key)
                                })
                              },
                              removeItem: function(key) {
                                return Promise.resolve().then(function () {
                                  return localStorage.removeItem(key)
                                })
                              },
                              length: localStorage.length
                          }
                          
                          const persistConfig = {
                            key: 'account',
                            storage: asyncLocalStorage,
                            whitelist: ['accounts']
                          }

                          const persistedReducer = Persist.persistReducer(persistConfig, rootReducer)

                          const store = Redux.createStore(
                            persistedReducer, 
                            {},
                            window.__REDUX_DEVTOOLS_EXTENSION__ ?
                              Redux.compose(Redux.applyMiddleware(Thunk.default),  window.__REDUX_DEVTOOLS_EXTENSION__()) : 
                              Redux.applyMiddleware(Thunk.default)
                          )

                          const persistor = Persist.persistStore(store)

                          const rrfProps = {
                            firebase,
                            config: rrfConfig,
                            dispatch: store.dispatch,
                            createFirestoreInstance: ReduxFirestore.createFirestoreInstance
                          }
        
                          ReactDOM.render(
                            <ReactRedux.Provider store={store}>
                              <RRF.ReactReduxFirebaseProvider {...rrfProps}>
                                
                                  <RPReactIntegration.PersistGate loading={ <div>Loading...</div> } persistor={ persistor }>
                                  <Suspense fallback={<LoadingScreen />}>
                                    <App />
                                  </Suspense>
                                  </RPReactIntegration.PersistGate>
                                
                              </RRF.ReactReduxFirebaseProvider>
                            </ReactRedux.Provider>,
                            
                            document.getElementById('app')
                          )

                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  })
})
