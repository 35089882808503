const GET_ALL_ACCOUNTS_REQ = 'GET_ALL_ACCOUNTS_REQ'
const GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS'
const GET_ALL_ACCOUNTS_ERROR = 'GET_ALL_ACCOUNTS_ERROR'
const GET_ALL_USERS_REQ = 'GET_ALL_USERS_REQ'
const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'
const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS'

export default {
    GET_ALL_ACCOUNTS_REQ,
    GET_ALL_ACCOUNTS_SUCCESS,
    GET_ALL_ACCOUNTS_ERROR,
    GET_ALL_USERS_REQ,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_ERROR,
    CLEAR_ACCOUNTS,
}