import 'regenerator-runtime/runtime'

import loginReducers from './app/login/redux/reducers'
import mailboxReducers from './app/mailbox/redux/reducers'
import authReducers from './app/auth/redux/reducers'
import accountSelectionReducers from './app/accountSelection/redux/reducers'
import adminReducers from './app/admin/redux/reducers'

const generateRootReducer = async () => {
    const Redux = await import('redux')
    const RRF = await import('react-redux-firebase')
    const Firestore = await import('redux-firestore')

    return(Redux.combineReducers({
        mailbox: mailboxReducers,
        login: loginReducers,
        auth: authReducers,
        accounts: accountSelectionReducers,
        admin: adminReducers,
        firebase: RRF.firebaseReducer,
        firestore: Firestore.firestoreReducer,
    }))
}

export default generateRootReducer